import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_word_break_column = _resolveComponent("word-break-column")!
  const _component_button_custom = _resolveComponent("button-custom")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    "empty-text": "Không tìm thấy kế hoạch",
    size: "small",
    "cell-style": {padding: '0', height: '15px'},
    border: true,
    data: _ctx.items
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "ID",
        width: "40"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.id), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_word_break_column, {
        width: "200",
        "header-title": "Tên",
        property: "ten_ke_hoach"
      }),
      _createVNode(_component_el_table_column, { label: "Ghi chú" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ghi_chu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Cấp sửa chữa" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.cap_sua_chua.ten_cap_sc), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Danh mục toa xe",
        width: "150"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.danh_muc_toa_xe.ten_danh_muc), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        width: "100",
        fixed: "right"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_button_custom, {
              "button-type": _ctx.ButtonsType.INFO,
              "color-type": _ctx.ButtonTypeColors.INFO,
              onClick: ($event: any) => (_ctx.push(`/quan-ly/ke-hoach-sua-chua/chi-tiet/${scope.row.id}`))
            }, null, 8, ["button-type", "color-type", "onClick"]),
            _createVNode(_component_button_custom, {
              "button-type": _ctx.ButtonsType.EDIT,
              "color-type": _ctx.ButtonTypeColors.SUCCESS,
              onClick: ($event: any) => (_ctx.push(`/quan-ly/ke-hoach-sua-chua/chinh-sua/${scope.row.id}`))
            }, null, 8, ["button-type", "color-type", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}