
import { defineComponent, PropType } from 'vue';
import { IKeHoachSuaChuaResponseBase } from '../../../../../core/interfaces/ApiResponses';
import { useButtonCustom } from '../../../../../core/hooks/use-button-custom';
import { useRouterCustom } from '../../../../../core/hooks/use-router-custom';
import WordBreakColumn from '../../../../../components/shared/work-break-column/WordBreakColumn.vue';
import ButtonCustom from '../../../../../components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
  name: 'ke-hoach-table',

  components: { ButtonCustom, WordBreakColumn },

  setup() {
    const { push } = useRouterCustom();
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    return { push, ButtonsType, ButtonTypeColors }
  },

  props: {
    items: {
      type: Array as PropType<Array<IKeHoachSuaChuaResponseBase>>
    }
  }
})
