
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import { setCurrentPageTitle } from '../../../../core/helpers/breadcrumb';
import { useLoading } from '../../../../core/hooks/use-loading';
import { useRouterCustom } from '../../../../core/hooks/use-router-custom';
import { IKeHoachSuaChuaResponseBase } from '../../../../core/interfaces/ApiResponses';
import { KeHoachSuaChuaService } from '../../../../core/services/KeHoachSuaChua.service';
import { getErrorMsg, swalNotification } from '../../../../core/helpers/utils';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';
import { usePagination } from '@/core/hooks/use-pagination';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import KeHoachTable from '@/views/crafted/pages/ke-hoach-sua-chua/ke-hoach-table/KeHoachTable.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';


export default defineComponent({
  name: 'ke-hoach-sua-chua-list',

  components: {
    ButtonCustom,
    KeHoachTable,
    PageRowLayout,
    Pagination,
    PerPageSelection,
  },

  setup() {
    const { startLoading, endLoading } = useLoading();

    const { push, goBack } = useRouterCustom();

    const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const { currentPage, totalItems, perPage, perPageArr, loading, searchTerm } = usePagination();

    return {
      currentPage, totalItems, perPage, perPageArr, loading, searchTerm,
      startLoading, endLoading,
      push, goBack,
      ButtonTypeColors, ButtonsType,
    }
  },

  data() {
    return {
      keHoachItems: [] as IKeHoachSuaChuaResponseBase[],
    }
  },

  async mounted() {
    setCurrentPageTitle('Kế hoạch sửa chữa');

    this.fetchKeHoach();
  },

  watch: {
		async currentPage() {
			await this.fetchKeHoach();
		},

		async perPage() {
			await this.fetchKeHoach();
		}
	},

  methods: {
    async fetchKeHoach() {
      try {
        this.startLoading();

        const { data: { data: { data, total } } } = await KeHoachSuaChuaService.list({
          per_page: this.perPage,
					current_page: this.currentPage,
					s: this.searchTerm,
        });

        this.keHoachItems = data;

        this.totalItems = total;

        this.endLoading();
      } catch (err) {
        this.endLoading();

        await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin kế hoạch sửa chữa'),
					'error',
				);
      }
    },

    searchKeHoach: debounce(function() {
      this.fetchKeHoach();
    }, 500),
  }
})
